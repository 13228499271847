// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-icons-js": () => import("./../../../src/pages/icons.js" /* webpackChunkName: "component---src-pages-icons-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-calc-en-js": () => import("./../../../src/templates/calc.en.js" /* webpackChunkName: "component---src-templates-calc-en-js" */),
  "component---src-templates-checkout-en-js": () => import("./../../../src/templates/checkout.en.js" /* webpackChunkName: "component---src-templates-checkout-en-js" */),
  "component---src-templates-index-en-js": () => import("./../../../src/templates/index.en.js" /* webpackChunkName: "component---src-templates-index-en-js" */),
  "component---src-templates-page-en-js": () => import("./../../../src/templates/page.en.js" /* webpackChunkName: "component---src-templates-page-en-js" */),
  "component---src-templates-sub-page-en-js": () => import("./../../../src/templates/sub_page.en.js" /* webpackChunkName: "component---src-templates-sub-page-en-js" */),
  "component---src-templates-wiki-en-js": () => import("./../../../src/templates/wiki.en.js" /* webpackChunkName: "component---src-templates-wiki-en-js" */)
}

